<style lang="scss"></style>

<template>
  <div>
    <section>
      <h4 class="mb-1">Overview</h4>

      <section class="d-flex flex-wrap align-items-start justify-content-between">
        <p>Applied policy Version: <b>{{ record.applied_policy_version }}</b></p>
        <p>Applied state: <b>{{ record.applied_state }}</b></p>
        <p>Last policy sync time: <b>{{ record.last_policy_sync_time | formatDateTime }}</b></p>
        <p>Last status sync time: <b>{{ record.last_status_report_time | formatDateTime }}</b></p>
        <p>Publish time: <b>{{ record.publish_time | formatDateTime }}</b></p>
        <p>Policy compliant: <b>{{ record.policy_compliant }}</b></p>
      </section>

      <section class="my-2">
        <h4 class="mb-1">Non compliance details</h4>
        <p v-if="nonComplianceRecords.length === 0">No issues currently</p>
        <b-card v-else no-body class="mb-0">
          <section>
            <b-table
              class="data-table"
              :responsive="true"
              hover
              :items="nonComplianceRecords"
              :fields="headers"
            />
          </section>
        </b-card>
      </section>
    </section>

    <b-button block class="mr-2" @click="$emit('close', true)">Close</b-button>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      record: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        nonComplianceRecords: [],
        headers: [
          {
            key: '',
            label: '',
          },
        ],
      };
    },
    mounted() {
      this.formatNonComplianceRecord(this.record.non_compliance_details);
    },
    methods: {
      formatNonComplianceRecord(item) {
        if (item) {
          this.nonComplianceRecords = JSON.parse(item);
        }
      },
    },
  };
</script>
