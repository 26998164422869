<template>
  <span v-if="appliedVersion===groupVersion" style="color: lightgreen">
    {{ appliedVersion }}
  </span>
  <span v-else>
    <span style="color: darkorange">
      {{ appliedVersion }}
    </span>
    <span v-b-tooltip.hover="'The device is pending policy update'">
      (<i class="fas fa-clock" /> {{ groupVersion }})
    </span>
  </span>
</template>

<style scoped lang="scss">

</style>
<script>

export default {
  props: {
    groupVersion: {
      type: Number,
      required: true,
    },
    appliedVersion: {
      type: Number,
      required: true,
    }
  }
}

</script>
