<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Device')">
    <b-card class="">
      <section>
        <h4 class="mb-2 font-weight-bolder">Policies</h4>

        <div class="row">
          <div class="col-3">
            <p>Policy Compliant: <b>{{device.policy_compliant | boolToYes}}</b></p>
          </div>

          <div class="col-3">
            <p>Last Policy Sync Time: <b>{{device.last_policy_sync_time | formatDateTime}}</b></p>
          </div>

          <div class="col-3">
            <p>Applied Policy Version: <b>{{device.applied_policy_version}}</b></p>
          </div>
        </div>
      </section>
    </b-card>
    <b-card class="mb-0" no-body>
      <p class="font-weight-bolder px-1 pt-1">Device Compliance</p>
      <section>
        <b-table
          class="data-table"
          hover
          responsive
          :items="records"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(last_policy_sync_time)="data">
            {{data.item.last_policy_sync_time | formatDateTime}}
          </template>
          <template #cell(publish_time)="data">
            {{data.item.publish_time | formatDateTime}}
          </template>
          <template #cell(action)="data">
            <b-button variant="primary" @click="showModal(data.item, 'modal-details')">View</b-button>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreRecords" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="p-2 pl-3" v-if="total_count === 0">No records found</b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-details" title="Compliance Details" size="lg" hide-footer>
      <device-compliance-detail @close="closeModals()" v-if="selectedRecord" :record="selectedRecord"></device-compliance-detail>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeviceService from '@/services/DeviceService';
import DeviceComplianceDetail from '@/views/devices/viewDevice/sections/compliance/DeviceComplianceDetail.vue';
import AdminDeviceService from "@/services/AdminDeviceService";

export default {
  name: 'DeviceCompliance',
  components: {
    DeviceComplianceDetail,
  },
  props: {
    device: {
      required: true,
      type: Object,
    },
    isAdminPage: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      selectedRecord: null,
      records: [],
      headers: [
        {
          key: 'publish_time',
          label: 'Publish time',
        },
        {
          key: 'applied_policy_version',
          label: 'Policy Version',
        },
        {
          key: 'applied_state',
          label: 'State',
        },
        {
          key: 'last_policy_sync_time',
          label: 'Policy synced at',
        },
        {
          key: 'policy_compliant',
          label: 'Policy Compliant',
        },

        {
          key: 'action',
          label: '',
        },
      ],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 10,
      },
      total_count: 0,
    };
  },
  mounted() {
    this.getComplianceRecords();
  },
  methods: {
    getMoreRecords(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getComplianceRecords();
    },
    getComplianceRecords() {
      let getRecordsPromise;

      if (this.isAdminPage) {
        getRecordsPromise = AdminDeviceService.getDeviceComplianceRecords(this.device.uid, this.filters)
      } else {
        getRecordsPromise = DeviceService.getDeviceComplianceRecords(this.device.uid, this.filters)
      }

      getRecordsPromise.then(res => {
        this.records = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get device compliance records, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    showModal(record, modalName) {
      this.setSelectedRecord(record);
      this.$bvModal.show(modalName);
    },
    setSelectedRecord(record) {
      this.selectedRecord = record;
    },
    closeModals() {
      this.$bvModal.hide('modal-details');
    },
  },
};
</script>
