import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/admin/devices';

export default {
  getAdminDevices(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getDevice(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  getDeviceComplianceRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/compliance${queryString}`);
  },
  getDeviceDataHistoryRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/data${queryString}`);
  },
};
