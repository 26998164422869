<style lang="scss">
.battery-comp {
  .battery {
    border: 3px solid #333;
    width: 18px;
    height: 28px;
    padding: 2px;
    border-radius: 4px;
    position: relative;
    margin: 15px 0;
    background-color: white;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid #fff;
      border-radius: 2px;
    }
  }

  .battery-level {
    background: #30b455;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;

    &.warn {
      background-color: #EFAF13;
    }

    &.danger {
      background-color: #e81309;
    }
  }
}
</style>
<!--eslint-disable-->
<template>
  <div class="battery-comp">
    <div class="battery">
      <div class="battery-level" :class="{'warn': (level < 50 && level >= 25), 'danger': level < 25}" :style="{'height': level + '%'}"></div>
    </div>
  </div>
</template>
<!--eslint-enable-->

<script>
export default {
  components: {},
  props: {
    level: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      test: '',
    }
  },
  mounted() {

  },
  methods: {},
}
</script>
