<style lang="scss">
.device-data-history {
  p {

    b {
      display: block;
      font-weight: normal;
    }
  }

  .card {
    min-height: 100%;
  }
}
</style>
<!--eslint-disable-->
<template>
  <div class="px-1">
    <div class="device-data-history" :class="{'p-2': modal}">
      <b-row>
        <b-col cols="12" md="3" class="mb-2 px-0 pr-md-1">
          <b-card class="">
            <b-row class="justify-content-center align-items-center">
              <b-col class="d-flex justify-content-center" cols="8">
                <b-img class="px-0" src="@/assets/images/devices/tablet.png" width="237" />
              </b-col>
              <b-col cols="4" class="px-0">
                <div v-if= "typeof data.battery_level === 'number' && data.battery_level >= 0 && data.battery_level <= 100"
                     class="d-flex justify-content-center align-items-center" v-b-tooltip="'Battery level recorded at ' + $options.filters.formatDateTime(data.battery_level_collected)">
                  <battery :level="data.battery_level" />
                  <b> {{ data.battery_level }}%</b>
                </div>
                <div v-else class="d-flex justify-content-center align-items-center" v-b-tooltip="'Battery level unavailable'">
                  <battery :level="0" />
                  <b> N/A </b>
                </div>
                <div class="d-flex justify-content-center text-center text-capitalize" v-b-tooltip.bottom="'Software version status'">
                    <b style="color: lime" v-if="data.software_info_update_status==='UP_TO_DATE'">
                      {{ data.software_info_update_status | stripUnderscore }}
                    </b>
                    <b style="color: red" v-else-if="data.software_info_update_status==='UNKNOWN_UPDATE_AVAILABLE'">
                      {{ data.software_info_update_status | stripUnderscore }}
                    </b>
                    <b v-else-if="data.software_info_update_status">
                      {{ data.software_info_update_status | stripUnderscore }}
                    </b>
                    <b v-else>N/A</b>
                  </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" md="9" class="mb-2 px-0 pl-md-1">
          <b-card no-body>
            <b-card-header>
              <b-card-title style="white-space: pre" class="d-flex font-weight-bolder p-0">
                <feather-icon size="20" icon="CpuIcon"/>
                Device Details
                <small><b-badge variant="success" class="ml-1">{{ device.status }}</b-badge></small>
              </b-card-title>
            </b-card-header>
            <b-row>
              <b-col>
                <table class="table table-responsive table-borderless">
                  <tbody>
                  <tr><td class="pr-0">Brand</td><td><b>{{device.hardware_info_brand}}</b></td></tr>
                  <tr><td class="pr-0">Hardware</td><td><b>{{device.hardware_info_hardware}}</b></td></tr>
                  <tr><td class="pr-0">Manufacturer</td><td><b>{{device.hardware_info_manufacturer}}</b></td></tr>
                  <tr><td class="pr-0">Serial Number</td><td><b>{{device.hardware_info_serial_number}}</b></td></tr>
                  <tr><td class="pr-0">Model</td><td><b>{{device.hardware_info_model}}</b></td></tr>
                  </tbody>
                </table>
              </b-col>
              <b-col>
                <table class="table table-responsive table-borderless">
                  <tbody>
                  <tr><td class="pr-0">Total Ram</td><td><b>{{device.memory_info_total_ram | toGigaBytes}}GB</b></td></tr>
                  <tr><td class="pr-0">Total Storage</td><td><b>{{device.memory_info_total_internal_storage | toGigaBytes}}GB</b></td></tr>
                  <tr><td class="pr-0">API Level</td><td><b>{{data.api_level}}</b></td></tr>
                  <tr><td class="pr-0">Android Version</td><td><b>{{data.software_info_android_version}}</b></td></tr>
                  <tr><td class="pr-0">Policy Version</td><td><b>
                    <PolicyInfo v-if="!modal" :group-version="device.group_policy_version" :applied-version="device.applied_group_policy_version"/>
                    <PolicyInfo v-else :group-version="data.applied_policy_version" :applied-version="data.applied_policy_version"/>
                  </b></td></tr>

                  </tbody>
                </table>
              </b-col>
              <b-col>
                <table class="table table-responsive table-borderless">
                  <tbody v-b-tooltip="'Temperatures recorded at ' + $options.filters.formatDateTime(data.battery_level_collected)">
                  <tr>
                    <td class="pr-0">Battery</td>
                    <td v-if="data.hardware_status_battery_temperatures !== '[]' && data.hardware_status_battery_temperatures !== 'null' && data.hardware_status_battery_temperatures !== ''">
                      <b :style="getTemperatureCategory(getTemperature(data.hardware_status_battery_temperatures))">
                        {{getTemperature(data.hardware_status_battery_temperatures)}}°C
                      </b>
                    </td>
                    <td v-else>N/A</td>
                  </tr>
                  <tr>
                    <td class="pr-0">CPU</td>
                    <td v-if="data.hardware_status_cpu_temperatures !== '[]' && data.hardware_status_cpu_temperatures !== 'null' && data.hardware_status_cpu_temperatures !== ''">
                      <b :style="getTemperatureCategory(getTemperature(data.hardware_status_cpu_temperatures))">
                        {{getTemperature(data.hardware_status_cpu_temperatures)}}°C
                      </b>
                    </td>
                    <td v-else>N/A</td>
                  </tr>
                  <tr>
                    <td class="pr-0">GPU</td>
                    <td v-if="data.hardware_status_gpu_temperatures !== '[]' && data.hardware_status_gpu_temperatures !== 'null' && data.hardware_status_gpu_temperatures !== ''">
                      <b :style="getTemperatureCategory(getTemperature(data.hardware_status_gpu_temperatures))">
                        {{getTemperature(data.hardware_status_gpu_temperatures)}}°C
                      </b>
                    </td>
                    <td v-else>N/A</td>
                  </tr>
                  <tr>
                    <td class="pr-0">Skin</td>
                    <td v-if="data.hardware_status_skin_temperatures !== '[]' && data.hardware_status_skin_temperatures !== 'null' && data.hardware_status_skin_temperatures !== ''">
                      <b :style="getTemperatureCategory(getTemperature(data.hardware_status_skin_temperatures))">
                        {{getTemperature(data.hardware_status_skin_temperatures)}}°C
                      </b>
                    </td>
                    <td v-else>N/A</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" class="mb-2 px-0">
          <b-card no-body class="">
            <b-card-header>
              <b-card-title style="white-space: pre" class="d-flex font-weight-bolder p-0">
                <feather-icon size="20" icon="ShieldIcon" />
                Security
              </b-card-title>
            </b-card-header>
            <b-card-body class="p-0">
              <b-row>
                <b-col md="6" class="mb-1 pr-md-1">
                  <table class="table table-striped">
                    <tbody>
                    <tr><td class="pr-1">Security Patch Level</td><td><b>{{ data.software_info_security_patch_level || 'N/A' }}</b></td></tr>
                    <tr><td class="pr-1">Security Posture Device</td><td><b>{{ data.security_posture_device }}</b></td></tr>
                    <tr><td class="pr-1">Encrypted</td><td><b>{{ data.is_encrypted | boolToYes }}</b></td></tr>
                    <tr><td class="pr-1">Device Secure</td><td><b>{{ data.is_device_secure | boolToYes }}</b></td></tr>
                    </tbody>
                  </table>
                </b-col>
                <b-col md="6" class="mb-1 pl-md-0">
                  <table class="table table-striped">
                    <tbody>
                    <tr><td class="pr-1">ADB Enabled</td><td><b>{{ data.adb_enabled | boolToYes }}</b></td></tr>
                    <tr><td class="pr-1">Development Settings Enabled</td><td><b>{{ data.development_settings_enabled | boolToYes }}</b></td></tr>
                    <tr><td class="pr-1">Unknown Sources Enabled</td><td><b>{{ data.unknown_sources_enabled | boolToYes }}</b></td></tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="12" class="mb-2 px-0">
          <b-card no-body class="">
            <b-card-header class="">
              <b-card-title style="white-space: pre" class="d-flex font-weight-bolder p-0">
                <feather-icon size="20" icon="InfoIcon" />
                Software Details
              </b-card-title>
            </b-card-header>
            <b-row>
              <b-col md="6" class="mb-1 pr-md-1">
                <table class="table table-striped">
                  <tbody>
                    <tr><td class="pr-1">Last Sync</td><td><b>{{ data.last_policy_sync_time | formatDateTime }}</b></td></tr>
                    <tr><td class="pr-1">Compliant</td><td><b>{{ data.policy_compliant | boolToYes}}</b></td></tr>
                    <tr><td class="pr-1">Wifi MAC address</td><td><b>{{ data.network_info_wifi_mac_address }}</b></td></tr>
                    <tr><td class="pr-1">Android Policy Version</td><td><b>{{ data.software_info_android_device_policy_version_name }}</b></td></tr>
                  </tbody>
                </table>
              </b-col>
              <b-col md="6" class="mb-1 pl-md-0">
                <table class="table table-striped mb-1">
                  <tbody>
                    <tr><td class="pr-1">Applied State</td><td><b>{{ data.applied_state }}</b></td></tr>
                    <tr><td class="pr-1">Common Criteria Mode Info Status</td>
                      <td v-if="data.common_criteria_mode_info_status==='COMMON_CRITERIA_MODE_ENABLED'"><b>Enabled</b></td>
                      <td v-else-if="data.common_criteria_mode_info_status==='COMMON_CRITERIA_MODE_DISABLED'"><b>Disabled</b></td>
                      <td v-else-if="data.common_criteria_mode_info_status">{{ data.common_criteria_mode_info_status | stripUnderscore  }}</td>
                      <td v-else><b>N/A</b></td></tr>
                    <tr><td class="pr-1">Last Status Report Time</td><td><b>{{ data.last_status_report_time | formatDateTime }}</b></td></tr>
                    <tr><td class="pr-1">Device Enrolled</td><td> <b>{{ device.enrollment_time | formatDateTime }}</b></td></tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-button v-if="modal" block class="mr-2" @click="$emit('close', true)">Close</b-button>
  </div>
</template>
<!--eslint-enable-->

<script>
import {BCardTitle} from "bootstrap-vue";
import Battery from "@/components/battery/Battery.vue";
import PolicyInfo from "@/views/devices/viewDevice/sections/PolicyInfo.vue";

export default {
  components: {
    Battery,
    BCardTitle,
    PolicyInfo
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false,
    },
    historicalData: {
      type: Object,
      required: false,
      default: null,
    },
    device: {
      required: true,
      type: Object,
    }
  },
  data() {
    return {
      data: this.historicalData ? this.historicalData : this.device.latest_data
    }
  },
  mounted() {
  },
  methods: {
    getTemperature(itemArray) {
      const temp = this.$options.filters.arrayToText(itemArray);
      if (temp.length > 0) {
        return parseFloat(temp).toFixed(1);
      }
      return temp;
    },
    getTemperatureCategory(temperature) {
      if (temperature >= 35) {
        return 'color: red';
      }
      if (temperature >= 25) {
        return 'color: yellow';
      }
      if (temperature >= 15) {
        return 'color: lime';
      }
      return 'color: cyan';
    },
  },
}
</script>
